import { mapActions, mapGetters } from 'vuex'
import { getItem } from '@/utils/storage'

export default {
  data() {
    return {
      passwordFieldType: 'password',
    }
  },
  computed: {
    ...mapGetters({ user: 'auth/USER', isAuth: 'auth/IS_AUTH' }),
    isLevier() {
      return (this.user && this.user.role && this.user.role.name == 'levier')
    },
    isAdmin() {
      return (this.user && this.user.role && this.user.role.name == 'admin')
    },
    isCashier() {
      return (this.user && this.user.role && this.user.role.name == 'cashier')
    },
    isSeller() {
      return (this.user && this.user.role && this.user.role.name == 'seller')
    },
    isOperator() {
      return (this.user && this.user.role && this.user.role.name == 'operator')
    },
    isDirector() {
      return (this.user && this.user.role && this.user.role.name == 'director')
    },
    isDeputy() {
      return (this.user && this.user.role && this.user.role.name == 'deputy')
    },
    isImagesChecker() {
      return (this.user && this.user.role && this.user.role.name == 'images_checker')
    },
    isHiredWorker() {
      return (this.user && this.user.role && this.user.role.name == 'hired_worker')
    },
    isOrgDepartment() {
      return (this.user && this.user.role && this.user.role.name == 'org_department')
    },
    getAll() {
      return { per_page: 999 }
    },
    storageUrlG() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    dollarRate() {
      const rate = getItem('realDollarRate')
      return Number(rate)
    },
    todayDate(type = 'ymd') {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      if (type == 'ymd') {
        today = `${yyyy}-${mm}-${dd}`
      } else {
        today = `${dd}.${mm}.${yyyy}`
      }
      return today
    },
  },
  methods: {
    hasPermission(value) {
      const { perms } = this.user.role
      let available = false
      if (perms) {
        if (value && value instanceof Array) {
          value.forEach(element => {
            available = available || (perms.includes(element))
          })
        } else {
          available = perms.includes(value)
        }
      }
      return available
    },
    nameByLocal(nameuz, nameru) {
      this.$i18n.locale == 'uz' ? nameuz : nameru
    },
    goTo(name, params, query) {
      this.$router.push({ name, params, query })
    },
    back() {
      window.history.back()
    },
    changeQuery(key, value) {
      const query = { ...{}, ...this.$route.query }
      if (value) {
        if (query[key] != value) {
          query[key] = value
          this.$router.push({ name: this.$route.name, params: this.$route.params, query })
        }
      } else {
        delete query[key]
        this.$router.push({ name: this.$route.name, params: this.$route.params, query })
      }
    },
    dateDotFormat(date) {
      let dateFormat = date
      if (date && date.search('-')) {
        dateFormat = date.slice(0, 10).split('-').reverse().join('.')
      }
      return dateFormat
    },
    formatDateYmd(val, format = 'DD.MM.YYYY HH:mm:ss') {
      if (val) {
        let value = val
        if (val.search('T') > 0) {
          value = val.replace('T', ' ').slice(0, 19)
        } else if (val.search('-')) {
          value = val.replace(/-/g, '/')
        }

        const dateClass = value != '' ? new Date(value) : new Date()
        if (format.search('YYYY') >= 0) {
          format = format.replace('YYYY', String(dateClass.getFullYear()))
        }
        if (format.search('MM') >= 0) {
          const monthInt = dateClass.getMonth() + 1
          const month = monthInt > 9 ? monthInt : (`0${monthInt}`)
          format = format.replace('MM', String(month))
        }
        if (format.search('DD') >= 0) {
          const day = dateClass.getDate() > 9 ? dateClass.getDate() : (`0${dateClass.getDate()}`)
          format = format.replace('DD', String(day))
        }
        if (format.search('DAY') >= 0) {
          format = format.replace('DAY', String(dateClass.getDate()))
        }
        if (format.search('HH') >= 0) {
          const hours = dateClass.getHours() > 9 ? dateClass.getHours() : (`0${dateClass.getHours()}`)
          format = format.replace('HH', String(hours))
        }
        if (format.search('mm') >= 0) {
          const minutes = dateClass.getMinutes() > 9 ? dateClass.getMinutes() : (`0${dateClass.getMinutes()}`)
          format = format.replace('mm', String(minutes))
        }
        if (format.search('ss') >= 0) {
          const seconds = dateClass.getSeconds() > 9 ? dateClass.getSeconds() : (`0${dateClass.getSeconds()}`)
          format = format.replace('ss', String(seconds))
        }
        if (format.search('MONTH') >= 0) {
          const monthInt = dateClass.getMonth()
          let monthName = String(dateClass.getMonth())
          switch (i18n.locale) {
            case 'ru':
              monthName = String(monthsRu[monthInt]).toLowerCase()
              break
            case 'oz':
              monthName = String(monthsUz[monthInt]).toLowerCase()
              break
            default:
              monthName = String(monthsCryl[monthInt]).toLowerCase()
              break
          }
          format = format.replace('MONTH', monthName)
        }
        return format
      }
      return ''
    },
    formatPhoneNumber(phoneNumberString) {
      if (phoneNumberString) {
        const formatted = phoneNumberString.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')
        return formatted
      }
      return null
    },
  },
}

export const _ = null
