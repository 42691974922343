// /* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import productCategory from './product-category'
import auth from './auth'
import region from './region'
import city from './city'
import warehouse from './warehouse'
import client from './client'
import user from './user'
import resource from './resource'
import sms from './sms'
import product from './product'
import loader from './loader'
import contract from './contract'
import setting from './setting'
import shop from './shop'
import role from './role'
import providers from './providers'
import buyers from './buyers'
import notification from './notification'
import parties from './parties'
import report from './report'
import imei from './imei'
import investment from './investment'
import attachLetter from './attachLetter'
import sevenTech from './sevenTech'
import paymentForProviders from './payment-for-providers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    region,
    city,
    client,
    warehouse,
    user,
    productCategory,
    sms,
    resource,
    loader,
    product,
    contract,
    setting,
    shop,
    role,
    attachLetter,
    providers,
    buyers,
    parties,
    report,
    imei,
    investment,
    notification,
    paymentForProviders,
    sevenTech,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
