import request from '@/utils/request'

export function roles(params) {
  return request({
    url: '/settings/roles',
    method: 'get',
    params,
  })
}

export function sendSms(data) {
  return request({
    url: '/send-sms',
    method: 'post',
    data,
  })
}

export function confirmSms(data) {
  return request({
    url: '/confirm-sms',
    method: 'post',
    data,
  })
}

export function reportStrategyList(params) {
  return request({
    url: '/settings/report-strategy-list',
    method: 'get',
    params,
  })
}

export function makeReport(data) {
  return request({
    url: '/make-report',
    method: 'post',
    data,
  })
}

export function calculateCreditAmount(data) {
  return request({
    url: '/calculate-credit-amount',
    method: 'post',
    data,
  })
}
export function calculatediscountAmount(data) {
  return request({
    url: '/calculate-discount-amount',
    method: 'post',
    data,
  })
}
export function calculateCreditSchedule(data) {
  return request({
    url: '/calculate-credit-schedule',
    method: 'post',
    data,
  })
}

export function makePaymentPdf(id) {
  return request({
    url: `/make-payment-pdf/${id}`,
    method: 'get',
  })
}

export function getClientReport(params) {
  return request({
    url: `/get-client-report/${params.id}`,
    method: 'get',
    params,
  })
}

export function makeContractPdf(params) {
  return request({
    url: `/make-contract-pdf/${params.id}`,
    method: 'get',
    params,
  })
}

export function makeReportPart(data) {
  return request({
    url: '/make-report-part',
    method: 'post',
    data,
  })
}

export function sendForExceptionSituations(data) {
  return request({
    url: '/send-for-exception-situations',
    method: 'post',
    data,
  })
}

export function confirmForExceptionSituations(data) {
  return request({
    url: '/confirm-for-exception-situations',
    method: 'post',
    data,
  })
}

export function unitOfMeasures(params) {
  return request({
    url: '/settings/unit-of-measures',
    method: 'get',
    params,
  })
}
export function clientReports(params) {
  return request({
    url: '/client-reports',
    method: 'get',
    params,
  })
}

export function storeUnitOfMeasure(data) {
  return request({
    url: '/settings/unit-of-measures',
    method: 'post',
    data,
  })
}

export function updateUnitOfMeasure(data) {
  return request({
    url: `/settings/unit-of-measures/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroyUnitOfMeasure(id) {
  return request({
    url: `/settings/unit-of-measures/${id}`,
    method: 'delete',
  })
}

export function getVisablePaymentProducts(params) {
  return request({
    url: '/warehouses/get-visible-payment-products',
    method: 'get',
    params,
  })
}

export function makeNakladnoyPdf(id) {
  return request({
    url: `/make-nakladnoy-pdf/${id}`,
    method: 'get',
  })
}

export function getPermissions(params) {
  return request({
    url: '/settings/permissions',
    method: 'get',
    params,
  })
}

export function partyStoreForHiredWorker(data) {
  return request({
    url: '/warehouses/party-store-for-hired-worker',
    method: 'post',
    data,
  })
}

export function getDollarRates(params) {
  return request({
    url: '/settings/dollar-rates',
    method: 'get',
    params,
  })
}

export function storeDollarRates(data) {
  return request({
    url: '/settings/dollar-rates',
    method: 'post',
    data,
  })
}
//
export function getRealDollarRates(params) {
  return request({
    url: '/settings/real-dollar-rates',
    method: 'get',
    params,
  })
}

export function storeRealDollarRates(data) {
  return request({
    url: '/settings/real-dollar-rates',
    method: 'post',
    data,
  })
}
// icloud api
export function getIClouds(params) {
  return request({
    url: '/i-clouds',
    method: 'get',
    params,
  })
}

export function storeICloud(data) {
  return request({
    url: '/i-clouds',
    method: 'post',
    data,
  })
}

export function getICloud(params) {
  return request({
    url: `/i-clouds/${params.id}`,
    method: 'get',
    params,
  })
}

export function updateICloud(data) {
  return request({
    url: `/i-clouds/${data.id}`,
    method: 'put',
    data,
  })
}
export function addSignalICloud(data) {
  return request({
    url: `/i-cloud/${data.id}/add-signal`,
    method: 'put',
    data,
  })
}
export function addBlockICloud(data) {
  return request({
    url: `/i-cloud/${data.id}/add-block`,
    method: 'put',
    data,
  })
}
export function detachICloud(data) {
  return request({
    url: '/i-cloud/detach',
    method: 'put',
    data,
  })
}
export function destroyICloud(id) {
  return request({
    url: `/i-clouds/${id}`,
    method: 'delete',
  })
}
export function attachICloud(data) {
  return request({
    url: '/i-cloud/attach',
    method: 'put',
    data,
  })
}

export function makeReportFromSelfShop(data) {
  return request({
    url: '/make-report-from-self-shop',
    method: 'post',
    data,
  })
}

export function getClickTransactions(params) {
  return request({
    url: '/click-transactions',
    method: 'get',
    params,
  })
}

export function getPhoneCode(params) {
  return request({
    url: '/settings/phone-codes',
    method: 'get',
    params,
  })
}
// Suggestions
export function getSuggestions(params) {
  return request({
    url: '/suggestions',
    method: 'get',
    params,
  })
}

export function storeSuggestion(data) {
  return request({
    url: '/suggestions',
    method: 'post',
    data,
  })
}

export function destroySuggestion(id) {
  return request({
    url: `/suggestions/${id}`,
    method: 'delete',
  })
}

export function getSuggestion(id) {
  return request({
    url: `/suggestions/${id}`,
    method: 'get',
  })
}
export function updateSuggestion(data) {
  return request({
    url: `/suggestions/${data.id}`,
    method: 'put',
    data,
  })
}

// Blacklist
export function getBlacklist(params) {
  return request({
    url: '/settings/blacklists',
    method: 'get',
    params,
  })
}

export function storeBlacklist(data) {
  return request({
    url: '/settings/blacklists',
    method: 'post',
    data,
  })
}
export function blacklistOut(params) {
  return request({
    url: '/settings/blacklist/out',
    method: 'get',
    params,
  })
}

export function destroyBlacklistItem(id) {
  return request({
    url: `/settings/blacklists/${id}`,
    method: 'delete',
  })
}

export function getBlacklistItem(id) {
  return request({
    url: `/settings/blacklists/${id}`,
    method: 'get',
  })
}
export function updateBlacklist(data) {
  return request({
    url: `/settings/blacklists/${data.id}`,
    method: 'put',
    data,
  })
}
//
export function cashbacksList(params) {
  return request({
    url: '/cashbacks',
    method: 'get',
    params,
  })
}
// Knox api
export function getKnoxes(params) {
  return request({
    url: '/knoxes',
    method: 'get',
    params,
  })
}

export function storeKnox(data) {
  return request({
    url: '/knoxes',
    method: 'post',
    data,
  })
}

export function getKnox(params) {
  return request({
    url: `/knoxes/${params.id}`,
    method: 'get',
    params,
  })
}

export function updateKnox(data) {
  return request({
    url: `/knoxes/${data.id}`,
    method: 'put',
    data,
  })
}
export function addSignalKnox(data) {
  return request({
    url: '/knox/send-message',
    method: 'put',
    data,
  })
}
export function addBlockKnox(data) {
  return request({
    url: '/knox/add-block',
    method: 'put',
    data,
  })
}
export function unblockKnox(data) {
  return request({
    url: '/knox/unblock',
    method: 'put',
    data,
  })
}

export function updateImeiProduct(data) {
  return request({
    url: '/knox/update-imei',
    method: 'put',
    data,
  })
}

export function knoxApprove(data) {
  return request({
    url: '/knox/approve',
    method: 'put',
    data,
  })
}

export function sendSelectedContractsUniaccess(data) {
  return request({
    url: '/send-selected-contracts-uniaccess',
    method: 'put',
    data,
  })
}

export function sendAllOverdueContractsUniaccess(data) {
  return request({
    url: '/send-all-overdue-contracts-uniaccess',
    method: 'put',
    data,
  })
}

export function sendManualAmountUniaccess(data) {
  return request({
    url: '/send-manual-amount-uniaccess',
    method: 'put',
    data,
  })
}

export function sendManualAmountOldUniaccess(data) {
  return request({
    url: '/send-manual-amount-old-uniaccess',
    method: 'put',
    data,
  })
}

export function getAutopayClients(params) {
  return request({
    url: '/uniaccess/clients',
    method: 'get',
    params,
  })
}
export function getAutopayClient(params) {
  return request({
    url: `/uniaccess/clients/${params.id}`,
    method: 'get',
    params,
  })
}

export function getAutopayPayments(params) {
  return request({
    url: '/uniaccess/payments',
    method: 'get',
    params,
  })
}
export function getAutopayPayment(params) {
  return request({
    url: `/uniaccess/payments/${params.id}`,
    method: 'get',
    params,
  })
}
export function autopayPaymentReject(data) {
  return request({
    url: `/uniaccess/payments/${data.id}/reject`,
    method: 'put',
    data,
  })
}
export function autopayToggle(data) {
  return request({
    url: '/uniaccess/client/toggle-autopay',
    method: 'post',
    data,
  })
}
// brands
const brandsSlug = '/settings/brands'

export function indexBrands(params) {
  return request({
    url: brandsSlug,
    method: 'get',
    params,
  })
}

export function showBrands(id) {
  return request({
    url: `${brandsSlug}/${id}`,
    method: 'get',
  })
}

export function storeBrands(data) {
  return request({
    url: brandsSlug,
    method: 'post',
    data,
  })
}

export function updateBrands(data) {
  return request({
    url: `${brandsSlug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroyBrands(id) {
  return request({
    url: `${brandsSlug}/${id}`,
    method: 'delete',
  })
}
// brand-models
const modelsSlug = '/settings/models'

export function indexBrandModels(params) {
  return request({
    url: modelsSlug,
    method: 'get',
    params,
  })
}

export function showBrandModels(id) {
  return request({
    url: `${modelsSlug}/${id}`,
    method: 'get',
  })
}

export function storeBrandModels(data) {
  return request({
    url: modelsSlug,
    method: 'post',
    data,
  })
}

export function updateBrandModels(data) {
  return request({
    url: `${modelsSlug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroyBrandModels(id) {
  return request({
    url: `${modelsSlug}/${id}`,
    method: 'delete',
  })
}
